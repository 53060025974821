import React, {
	useEffect
	, useState
} from 'react';
import {
	IVR_CONNECT_OPTIONS
	, IVR_CONNECT_MAP
} from '../../../common/v5/constants';
import {
	TextInputRow,
	SelectInputRow,
	FormInputWithLabelRow
} from '../../../reactcomponents/Form';
import { FlowDiagram } from '../../../components/v5/FlowDiagram';
import PopupPage from '../../../reactcomponents/PopupPage';
import { buttonListIVR, buttonMap, OneButtonPad, ButtonsPad, BTTN_TWO } from '../../../reactcomponents/DialPad';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import styled from 'styled-components';
import { centionBlue } from '../../../styles/_variables';
import {
	createWithPropAttachedAction
} from '../../../reactcomponents/hocs';
import UploadButton from '../../../reactcomponents/UploadButton';
import Helper from '../../../reactcomponents/Helper';

export const IVRPreview = ({ show, title, data, onDelete, onClose }) => {
	return <PopupPage
		data-qa-id="ivr-preview-backdrop-popup"
		extraClass="ivr-preview-backdrop"
		innerClass="ivr-preview"
		show={show}
		onClose={onClose}
	>	<div id="diagram" style={{height: '100%'}}>
			<div className="popup-title">
				<h2><i className="icon-keypad"></i>{title}</h2>
			</div>
			<FlowDiagram name={title} data={data} onDelete={onDelete} onClose={onClose} />
		</div>
	</PopupPage>
}

const StyledTitleBullet = styled.span`
	font-size: 16px;
	color: #FFF;
	background: ${centionBlue};
	padding: 2px 6px;
	margin-right: 5px;
	border-radius: 4px;
`;

const PlayIconButton = ({id, src, onPlayAudioError}) => {

	const [playing, setPlay ] = useState(false);
	const [audio, setAudio ] = useState("");

	function playSound() {
		if(audio) {
			audio.play();
			setPlay(true);
		} else {
			onPlayAudioError();
		}
	}

	function stopSound() {
		audio.pause();
		audio.currentTime = 0;
		setPlay(false);
	}

	useEffect(() => {
		if(src) {
			const audioFile = new Audio(src);
			setAudio(audioFile);
		}
	}, [src]);

	useEffect(() => {
		if(audio) {
			audio.addEventListener('ended', handleAudioEnded);
			return () => {
				audio.removeEventListener('ended', handleAudioEnded);
			};
		}
	}, [audio]);

	function handleAudioEnded() {
		setPlay(false);
	}

	return (
		<div id={id} style={{background: '#FFF', borderRadius: '4px', padding: '5px', color: centionBlue, width: '30px', border: '1px solid '+centionBlue, textAlign: 'center' }} onClick={playing ? stopSound : playSound}>
			<i style={{color: centionBlue}} className={playing ? 'icon-stop-filled' : 'icon-play-filled'}></i>
		</div>
	)
}

const Upload = createWithPropAttachedAction("id", "onConfirm")(UploadButton);

const UploadAudio = ({ivrId, id, name, src, onConfirmUpload, onUploadPrompt, onPlayAudioError, ...props}) => {
	function handleUpload(e) {
		let fileName;
		e.preventDefault();
		let dataFiles = [];
		Object.keys(e.target.files).forEach( i =>{
			const formData = new FormData();
			fileName = e.target.files[i].name;
			let boundary = Math.floor(Math.random() * 6)+ ''+ i +''+ Math.floor(''+new Date() / 1000) ;
			formData.append( 'uploadfile', e.target.files[i]);
			formData.append( 'filename', e.target.files[i].name);
			formData.append( 'eventType', 'upload');
			formData.append( 'random', parseFloat(boundary));
			formData.append( 'field', name);
			formData.append( 'callflowId', id);

			dataFiles.push(formData);
		});
		onUploadPrompt(ivrId, id, fileName, name, dataFiles[0]);
	}
	return (
		<div className="ivr-prompt-upload">
			<PlayIconButton id={name} src={src} onPlayAudioError={onPlayAudioError} />
			<Upload id={name} text={I("Upload")} accept=".wav" onChange={handleUpload} className="ivr-upload-btn" {...props} />
		</div>
	)
}

const IVRBreadCrumb = ({tree = [], onClick={onClick}}) => {
	let paths = [];
	tree.map(cf => {
		paths.push(<BreadcrumbItem key={"ivrPath-"+cf.id} active tag="span" onClick={() => onClick(cf.ivrId, cf.id, cf.level)}>{cf.name}</BreadcrumbItem>);
	})
	return (
		<Breadcrumb className="ivr-breadcrumb" listClassName="ivr-breadcrumb-list">
			<BreadcrumbItem tag="span" href="#">
				<i className='icon-call'></i>
			</BreadcrumbItem>
			{paths}
		</Breadcrumb>
	)
}

//This will generate breadcrumb tree, with name and id, based on which ivr prompt is currently opened
function generateBreadcrumb(data, currentId, activeId) {
	const breadcrumb = [];
	if(activeId > 0) {
		let current = data.find(item => item.id === currentId);
		while (current) {
			const breadcrumbSet = {
				ivrId: activeId,
				id: current.id,
				name: current.name,
				level: current.level
			}
			breadcrumb.unshift(breadcrumbSet);
			current = data.find(item => item.id === current.parentid);
		}
	}
	return breadcrumb;
}

const CallIVREditForm = ({
	hidden
	, activeId
	, input
	, mainPrompt
	, baseButtons
	, ivrTreeData
	, showPreview
	, onHidePreview
	, onChangeAdminInput
	, onHandleTextInputBlur
	, onChangeCallflow
	, onRemoveOneKey
	, onLoadCallflow
	, onConfirmUpload
	, onUploadPrompt
	, onUploadMOH
	, onPlayAudioError
	, onDelete
}) => {

	const handleNoClick = () => {
		//do nothing
	}

	const handleChangeName = (e) => {
		const promptId = mainPrompt.id;
		const pid = mainPrompt.parentId ? mainPrompt.parentId : "";
		let field = e.target.name, value = e.target.value;

		onChangeCallflow(promptId, input.ivrConnLevel, "name", value, pid, activeId);
		onChangeAdminInput(field, value);
	}

	const handleChangeDesc = (e) => {
		const promptId = mainPrompt.id;
		const pid = mainPrompt.parentId ? mainPrompt.parentId : "";
		let field = e.target.name, value = e.target.value;

		onChangeCallflow(promptId, input.ivrConnLevel, "description", value, pid, activeId);
		onChangeAdminInput(field, value);
	}

	let hideDTMFBtn = true;
	if(input.ivrConnLevel !== 0) {
		hideDTMFBtn = false;
	}

	let secondary = "";
	if(mainPrompt && mainPrompt.dtmftobehere) {
		const sub = buttonMap[mainPrompt.dtmftobehere][1];
		secondary = sub ? sub : "";
	}

	const ivrPathBreadcrumb = generateBreadcrumb(input.ivrCallflow, input.ivrPromptId, activeId);

	return (
		<React.Fragment>
			<IVRBreadCrumb tree={ivrPathBreadcrumb} onClick={onLoadCallflow}/>
			<form id="callIVREdit" className="admin-one-form edit-admin-form ivr-admin-form" hidden={hidden}>
				<div style={{margin: '20px 0px 0px 10px'}} className='c3-simple-dialpad dialpad compact ivr-admin' hidden={hideDTMFBtn}>
					<div className='dials'>
						<span style={{fontWeight: '700', fontSize: '12px', color: '#6D6D6D'}}>{I("On keypress")}</span>
						<div style={{marginTop: '5px'}}>
							<OneButtonPad
								key={"ivrCfKey-"+mainPrompt.dtmftobehere}
								id={mainPrompt.dtmftobehere ? mainPrompt.dtmftobehere : BTTN_TWO}
								isPressed={false}
								onClick={handleNoClick}
								primary={mainPrompt.dtmftobehere}
								secondary={secondary}
							/>
						</div>
					</div>
				</div>
				<div className='ivr-admin-separator'>
					<div className="ivr-title-prompt"><StyledTitleBullet>A</StyledTitleBullet>{I("Starting prompt")}</div>
					<div className='admin-input-wrapper-half'>
						<TextInputRow
							name="ivrName"
							className="admin-text-input with-helper"
							label={I(`Name`)}
							value={input.ivrName}
							onChange={handleChangeName}
							onBlur={onHandleTextInputBlur}
							warning={""}
							inlineLabel={false}
							mandatory={true}
						/>
						<TextInputRow
							id={"admin-ivrDescription"}
							name="ivrDesc"
							className="admin-text-input with-helper"
							label={I(`Description`)}
							value={input.ivrDesc}
							onChange={handleChangeDesc}
							onBlur={onHandleTextInputBlur}
							inlineLabel={false}
						/>
					</div>
					<div className='admin-input-wrapper-half'>
						<div className='ivr-upload-wrapper'>
							<TextInputRow
								id={"admin-ivrGreetingAudio"}
								name="ivrGreetingFile"
								className="admin-text-input with-helper"
								label={I(`Greeting`)}
								value={mainPrompt.prompt || ""}
								warning={""}
								inlineLabel={false}
								readonly={true}
							/>
							<UploadAudio ivrId={activeId} id={mainPrompt.id} name={"ivrGreetingAudioFile"} src={mainPrompt.promptdownload} onUploadPrompt={onUploadPrompt} onConfirmUpload={onConfirmUpload} onPlayAudioError={onPlayAudioError} />
							<Helper>{I("Greeting audio file for this prompt")}</Helper>
						</div>
						<div className='ivr-upload-wrapper' hidden={input.ivrConnLevel > 0 ? true : false}>
							<TextInputRow
								id={"admin-ivrOffWhMsgAudio"}
								name="ivrOffWhMsgAudio"
								className="admin-text-input with-helper"
								label={I(`Off working hours message`)}
								value={input.ivrOffhoursMsgFile || ""}
								readonly={true}
								inlineLabel={false}
							/>
							<UploadAudio ivrId={activeId} id={mainPrompt.id} name={"ivrOffhoursMsgFile"} src={input.ivrOffhoursMsgFileDownload} onUploadPrompt={onUploadPrompt} onPlayAudioError={onPlayAudioError} />
							<Helper>{I("There can only be one audio file for each workspace. All IVR will be using the same file")}</Helper>
						</div>
					</div>
					<div className='admin-input-wrapper-half' hidden={input.ivrConnLevel > 0 ? true : false}>
						<div className='ivr-upload-wrapper'>
							<TextInputRow
								id={"admin-ivrQueueMusic"}
								name="ivrQueueMusic"
								className="admin-text-input with-helper"
								label={I(`Queue music`)}
								value={input.ivrQueueMusicFile || ""}
								readonly={true}
								warning={""}
								inlineLabel={false}
							/>
							<UploadAudio ivrId={activeId} id={mainPrompt.id} name="ivrQueueMusicFile" src={input.ivrQueueMusicFileDownload} onUploadPrompt={onUploadMOH} onPlayAudioError={onPlayAudioError} />
							<Helper>{I("There can only be one audio file for each workspace. All IVR will be using the same file")}</Helper>
						</div>
						<div className='ivr-upload-wrapper' hidden={input.ivrConnLevel > 0 ? true : false}>
							<TextInputRow
								id={"admin-ivrBreakMsgAudio"}
								name="ivrBreakMsgAudio"
								className="admin-text-input with-helper"
								label={I(`Break hours message`)}
								value={input.ivrBreakhoursMsgFile || ""}
								readonly={true}
								inlineLabel={false}
							/>
							<UploadAudio ivrId={activeId} id={mainPrompt.id} name={"ivrBreakhoursMsgFile"} src={input.ivrBreakhoursMsgFileDownload} onUploadPrompt={onUploadPrompt} onPlayAudioError={onPlayAudioError} />
							<Helper>{I("There can only be one audio file for each workspace. All IVR will be using the same file")}</Helper>
						</div>
					</div>
				</div>
				<div className='ivr-admin-separator'>
					<ConnectingPrompt ivrId={activeId} promptKeyId={input.ivrPromptId} level={input.ivrConnLevel} callflow={input.ivrCallflow} mainPrompt={mainPrompt} onLoadCallflow={onLoadCallflow} onChangeCallflow={onChangeCallflow} onRemoveOneKey={onRemoveOneKey} onChangeAdminInput={onChangeAdminInput} onHandleTextInputBlur={onHandleTextInputBlur} />
				</div>
				{
					showPreview &&
					<IVRPreview show={showPreview} title={input.ivrCallflow[0] ? input.ivrCallflow[0].name : ""} data={ivrTreeData} onDelete={() => onDelete(activeId)} onClose={onHidePreview} />
				}
				<div style={{ marginTop: '50px' }}>
					{baseButtons}
				</div>
			</form>
		</React.Fragment>
	)
}

// Generate unique id based on available ids
// in the form of 1.0 ...onwards
// use level as the starting number for example, level 3, starts with 3.0
function generateUniqueId(prefix, existingStrings) {
	let maxId = -1;

	if (existingStrings.length === 0) {
		return prefix + ".0";
	}

	for (let i = 0; i < existingStrings.length; i++) {
		const idString = existingStrings[i];
		if (idString.startsWith(prefix + ".")) {
			const id = parseFloat(idString.substring(prefix.toString().length + 1));
			if (id > maxId) {
				maxId = id;
			}
		}
	}

	const newId = (maxId + 1).toFixed();
	return prefix + "." + newId;
}

const ConnectingPrompt = ({ivrId, promptKeyId, level, mainPrompt, callflow, onLoadCallflow, onChangeCallflow, onRemoveOneKey, onChangeAdminInput, onHandleTextInputBlur}) => {

	//TODO: Add textfield "connecttoqueue" for each prompt key (sales, enquiry or support) - hardcoded
	const [keyPressed, setKeyPressed] = useState([]);

	useEffect(() => {
		//Getting callflow entry
		let parent = {};
		let child = [];
		if(callflow && callflow.length > 0) {
			let pid;
			for(let i=0;i<callflow.length;i++) {
				if(callflow[i].level === level) {
					parent = callflow[i];
					pid = callflow[i].id;
				} else {
					//check for the child for this entry
					if(callflow[i].level === level+1) {
						if(promptKeyId) {
							if(callflow[i].parentid === promptKeyId) {
								child.push(callflow[i]);
							}
						} else {
							if(callflow[i].parentid === pid) {
								child.push(callflow[i]);
							}
						}
					}
				}
			}

			let keys = [];
			if(child && child.length > 0) {
				for(let i=0;i<child.length;i++) {
					keys.push({
						id: child[i].dtmftobehere,
						keyId: child[i].id,
						parentId: child[i].parentid,
						name: child[i].name,
						actiontype: child[i].actiontype,
						connecttoqueue: child[i].connecttoqueue
					});
				}
				setKeyPressed(keys);
			} else {
				setKeyPressed([]);
			}
		}
	}, [callflow, level, promptKeyId]);

	const addKeyClick = (val) => {
		let keyExist = keyPressed.find(item => item.id === val.toString());
		if(!keyExist) {
			let allIds = [];
			callflow.map(key => {
				if(key.level == level+1) {
					allIds.push(key.id);
				}
			});
			let promptId = ivrId;
			promptId = generateUniqueId(level+1, allIds);

			onChangeCallflow(promptId, level+1, "dtmftobehere", val, promptKeyId, ivrId);

			setKeyPressed([
				...keyPressed,
				{ id: val, keyId: promptId, parentId: promptKeyId ,name: "", description: "", actiontype: "" }
			]);
		} else {
			onRemoveOneKey(keyExist.keyId);
		}
	};

	const handleChangeConnectTo = (val) => {
		onChangeCallflow(mainPrompt.id, level, "actiontype", val, promptKeyId, ivrId);
	}

	const onRemoveKeyPress = (id) => {
		onRemoveOneKey(id);
	}

	const connectOptionHelper = <IVRConnectHelper />;

	return(
		<React.Fragment>
			<div className='input-section'>
				<div className="ivr-title-prompt"><StyledTitleBullet>B</StyledTitleBullet>{I("Connecting prompt")}</div>
				<FormInputWithLabelRow
					label={I('Connect to')}
					mandatory={true}
					inlineLabel={false}
				>
					<SelectInputRow
						id={"ivr-connectTo"}
						name={"ivr-connectTo"}
						className={"admin-select-ivr-connect-to"}
						option={IVR_CONNECT_OPTIONS}
						mandatory={true}
						value={IVR_CONNECT_MAP[mainPrompt.actiontype]}
						textNoItemSelected={I("Please select")}
						onSelect={handleChangeConnectTo}
						readonly={false}
						helperTxt={connectOptionHelper}
					/>
				</FormInputWithLabelRow>
			</div>
			<div className='input-section'>
				<span className="ivr-keypress-title">{I("Select keypress")}</span>
				<div className='c3-simple-dialpad dialpad compact ivr-admin'>
					<IVRKeyButtonsElem onClick={addKeyClick} pressedKeyList={keyPressed} />
				</div>
				<KeyPressElem ivrId={ivrId} parentId={promptKeyId} keyItems={keyPressed} level={level} onLoadCallflow={onLoadCallflow} onChangeCallflow={onChangeCallflow} onRemoveKeyPress={onRemoveKeyPress} onChangeAdminInput={onChangeAdminInput} onHandleTextInputBlur={onHandleTextInputBlur} />
			</div>
		</React.Fragment>
	)
}

const IVRConnectHelper = () => {
	return (
		<div>
			<span>{I("Agent")} :</span><br />
			{I("Call specific agent. Example, Operator.")}
			<br /><br />
			<span>{I('Callback')}:</span><br />
			{I("Customer will send a callback request.")}
			<br /><br />
			<span>{I("Call queue")}:</span><br />
			{I("Queue the call to any available agents.")}
			<br /><br />
			<span>{I("Phone menu")}</span><br />
			{I("Customer will be able to press singular button on keypad and will route to the desired destination.")}
		</div>
	)
}

const IVRKeyButtonsElem = ({ callInProgress = false, onClick, pressedKeyList, ...props }) => {
	const keyList = pressedKeyList.map(obj => obj.id);
	return (
		<ButtonsPad disabled={callInProgress}>
		{buttonListIVR.map(identifier => {
			const [
				primary
				, secondary
				, isIcon
				, className
			] = buttonMap[identifier];
			return (
				<OneButtonPad
					key={identifier}
					className={className}
					disabled={callInProgress}
					id={identifier}
					isPressed={keyList.indexOf(identifier.toString()) !== -1 ? true : false}
					onClick={onClick}
					primary={primary}
					primaryIsIcon={isIcon}
					secondary={secondary}
				/>
			);

		})}
	</ButtonsPad>
	)
}

const KeyPressHeader = () => {
	return <div className="ivr-keypress-elem item-header">
		<div className='ivr-keypress-elem-item key'>{I("Key")}</div>
		<div className='ivr-keypress-elem-item name'>{I("Name")}</div>
		<div className='ivr-keypress-elem-item name'>{I("Connect to")}</div>
		<div className='ivr-keypress-elem-item action'>&nbsp;</div>
		<div className='ivr-keypress-elem-item action'>&nbsp;</div>
	</div>
}

const KeyRowItem = ({id, ivrId, parentId, keyItem, level, onRemoveKeyPress, onLoadCallflow, onChangeCallflow, ...props}) => {
	const removeKey = (id) => {
		onRemoveKeyPress(id);
	}
	const openSubMenu = (id) => {
		onLoadCallflow(ivrId, id, level+1);
	}
	const setName = (e) => {
		onChangeCallflow(keyItem.keyId, level+1, "name", e.target.value, parentId, ivrId);
	}
	const setConnectTo = (val) => {
		onChangeCallflow(keyItem.keyId, level+1, "actiontype", val, parentId, ivrId);
	}
	const setConnectToQueue = (e) => {
		onChangeCallflow(keyItem.keyId, level+1, "connecttoqueue", e.target.value, parentId, ivrId);
	}
	const [
		primary
		, secondary
	] = buttonMap[id];
	return(
		<div className="ivr-keypress-elem">
			<div className='ivr-keypress-elem-item key'>
				<div className='c3-simple-dialpad dialpad compact ivr-admin'>
					<div className='dials'>
						<OneButtonPad primary={primary} secondary={secondary} />
					</div>
				</div>
			</div>
			<div className='ivr-keypress-elem-item name'>
				<TextInputRow
					id={"admin-ivr-name-"+id}
					name={"ivrKeyName-"+id}
					className="admin-text-input"
					value={keyItem.name}
					onChange={setName}
					onBlur={props.onHandleTextInputBlur}
					inlineLabel={false}
				/>
			</div>
			<div className='ivr-keypress-elem-item name' style={{display: 'block'}}>
				<SelectInputRow
					id={"ivr-key-connectTo-"+id}
					name={"ivr-key-connectTo-"+id}
					className={"admin-select-ivr-connect-to"}
					option={IVR_CONNECT_OPTIONS}
					mandatory={true}
					value={IVR_CONNECT_MAP[keyItem.actiontype]}
					textNoItemSelected={I("Please select")}
					onSelect={setConnectTo}
					readonly={false}
				/>
				<div hidden={ IVR_CONNECT_MAP[keyItem.actiontype] !== IVR_CONNECT_MAP['returnqueue'] }>
					<TextInputRow
						id={"admin-ivr-connecttoqueue-"+id}
						name={"ivr-key-connectoqueue-"+id}
						className="admin-text-input"
						value={keyItem.connecttoqueue || ""}
						onChange={setConnectToQueue}
						onBlur={props.onHandleTextInputBlur}
						inlineLabel={false}
						helperTxt={I("Set the SIP queue ID that has been setup in area setting")}
						warning={ keyItem.connecttoqueue == "" ? I("Please enter SIP queue ID") : "" }
				/>
				</div>
			</div>
			<div className='ivr-keypress-elem-item action'><span onClick={() => removeKey(keyItem.keyId) }><i className='icon-trash'></i></span></div>
			<div className='ivr-keypress-elem-item action next'><span onClick={() => openSubMenu(keyItem.keyId) }><i className='icon-chevron-right'></i></span></div>
	</div>
	)
}

const KeyPressElem = ({ivrId, parentId, keyItems, level, onLoadCallflow, onChangeCallflow, onRemoveKeyPress, ...props}) => {
	return (
		<React.Fragment>
			<KeyPressHeader />
			{keyItems.map((info, index) => (
				<KeyRowItem id={info.id} key={"keypressrow-"+info.id+"-"+index} ivrId={ivrId} parentId={parentId} level={level} keyItem={info} onLoadCallflow={onLoadCallflow} onChangeCallflow={onChangeCallflow} onRemoveKeyPress={onRemoveKeyPress} {...props} />
			))}
		</React.Fragment>
	);
}

export default CallIVREditForm;
