import { compose } from 'recompose';
import { connect } from 'react-redux';
import { push } from '../../common/v5/utils';
import { default as Admin, AdminList, AdminEdit, AdminEditLeftView, AdminEditRightView, AdminEditInline } from './admin';
import { AgentPref, default as Agent, AgentForm } from '../../components/v5/Agent';
import PopupInsertProtect from '../../components/v5/PopupInsertProtect';
import {
	insertEditorContent
	, resetInsertionEditorContent
	, toggleAdminSidebar
	, changeAdminView
	, toggleAgentEdit
	, setAgentInput
	, toggleAdminEdit
	, setAdminInput
	, resetAdminInput
	, getAdminDataFromList
	, filterAdminList
	, setAdminListFilter
	, sortAdminTableFields
	, sortAdminTableOrder
	, checkSoundOutputDevices
	, resetEmailAvail
	, adminActionAlert
	, adminActionStatus
} from '../../redux/actions/admin';
import {
	closeInsertProtect
	, showInsertProtect
} from '../../redux/actions/common';
import { togglePopAlert } from '../../redux/actions/hmf';
import { agentNameSelector } from '../../redux/selectors/hmf';
import {
	selectToggleSideBar,
	selectCollapseSideBar,
	resetWorkflowView
} from '../../redux/actions/workflow';
import {
	downloadReviewAddress,
	fetchAgentData,
	fetchAgentSalutations,
	fetchAgentSignatures,
	idPersonalParam,
	addNewAgentMP,
	deleteAgentMP,
	addAgentPrefAvatar,
	deleteAgentPrefAvatar,
	openEditForm,
	agentCreateNew,
	AgentValidateExternalID,
	doGetTwoFAGetNewSecret,
	doVerifyTwoFAToken,
	fetchAdminList,
	getWordlistData,
	setAdminData,
	uploadReviewAddresses,
	removeAdminData,
	fetchOneSignature,
	fetchOneSalutation,
	removeEEAvatar,
	sstAsyncs,
	doActivateAgent,
	doDeactivateAgent,
	doUnlockAgent,
	agentUploadListAttachment,
	uploadAutoTagFile,
	validateAgent,
	stopRunAgentImportStatus,
	oneFileArchive,
	doDownloadFileArchive,
	doActivateAgentSipStatus,
	doActivateStunTurnStatus,
	validateAgentSipStatus,
	checkAgentEmailAvail,
	uploadTagsFile,
	adminTagAppend,
	uploadAddressBookList,
	fetchSkillProficiency,
	fetchSkills,
	saveAgentSkill,
	removeSkillAgent,
	onceLanguageList
} from '../../redux/actions/async/admin';
import {
	create as createAdminAgentAccessKey,
	deleteKey as deleteAdminAgentAccessKey,
	list as listAdminAgentAccessKeys
} from '../../redux/actions/async/agentAccessKey';
import { adminAgentAccessKeyURL } from '../../redux/actions/async/ajax';
import { onceKnowledgeBaseList, getTwoFAGetNewSecretAsync } from '../../redux/actions/async/workflow';
import { insertProtectedReplyContent } from '../../redux/actions/async/cke';
import {
	IP_ADMIN
	, IP_MANUAL
	, IP_WORKFLOW
	, adminAdmin
	, currentSortFieldMemo
	, currentSortOrder
	, getAdminListSelector
	, getAdminChannelSelector
	, getStunTurnServices
	, getStunTurnList
	, getCurrentAdminView
	, insertProtectCkeditor
	, insertProtectCkeditorSettings
	, listColumnSelector
	, fileArchiveAdminSelector
	, selectedFileArchive
	, routingTagsSelector
	, getAgentListSelector
	, getAdminListDefSelector
	, tagsListSelector
	, subTagsListSelector
	, getNonExpandables
	, jwtChannelSelector
	, jwtUserSelector
	, jwtAPIVersion
	, jwtChannelAccSelector
	, skillProficiencySelector
	, skillsAdminSelector
	, getAdminListWipSelector
	, getAdminLanguageList
} from '../../redux/selectors/admin';
import {
	adminAgentAccessKeysSelector
} from '../../redux/selectors/agentAccessKey';
import {
	fileArchivesSelector
	, getCollabChannelsListSelector
} from '../../redux/selectors/errand';
import {
	ckeditorSettings
	, normalizedOrgAreasMemoize
	, onlyActiveAreasSelector
	, onlyActiveConnectedAreasSelector
	, getCollabSlackEnabled
	, getCollabJiraEnabled
	, getCollabMSTeamEnabled
	, getCollabGoogleChatEnabled
	, getMyId
} from '../../redux/selectors/workflow';
import {
	allowMultipleChatbotProvidersMemo,
	knowledgebasesMemo,
	providersMemo
} from '../../redux/selectors/chatbot';
import { servicesByType } from '../../redux/selectors/server';
import {
	M_MY_SALUTATION
	, M_MY_SIGNATURE
	, M_REVIEW_KEYWORDS
	, M_REVIEW_AGENTS
	, M_ROUTING_KEYWORDS
	, M_SALUTATION
	, M_SIGNATURE
	, M_TEMPLATE
	, M_TEMPLATE_WHATSAPP
	, M_ERRANDINTERNALSTATE
	, ADMIN_VIEW_MAP
	, emptyArray
	, M_CHATWIDGETDL
	, M_FAQWIDGETDL
	, M_VOICEWIDGETDL
	, M_AGENTSIPSTATUS
	, M_CLASSIFIER
	, WHATSAPP_INTERACTIVE
	, M_STUN_TURN
	, M_QUICK_REPLY
	, M_TAG
	, M_API_CALLBACK
	, M_API_ACCESSTOKENS
	, M_CALL_IVR
	, M_GENERATIVE_AI_DOCUMENT
} from '../../common/v5/constants';
import { withAgentTimezonOffset } from '../../containers/hocs';
import { isBlacklist, isBlacklistResp, isToolManager, isWhitelist } from '../../common/v5/helpers';


const mapAgentStateToProp = store => {
	const server = store.server, app = store.app, admin = app.admin, ui = admin.ui;
	const s = {
		agentData: [],
		data: []
	};
	return s;
};

const mapAgentDispatchToProp = (dispatch, props) => {
	return {
		onLoad: () => {

		}
	}
};

export const AgentCtnr = connect(mapAgentStateToProp, mapAgentDispatchToProp)(Agent);

const mapAgentEditStateToProp = store => {
	const server = store.server, app = store.app, admin = app.admin, ui = admin.ui;
	const agentData = admin.agentData, data = agentData.data, newAgent = admin.agentNew;
	const feat = server.features, wf = store.app.workflow, wfs = wf.fetchWfSettings;
	let agentLockFieldsEdit = feat["agent.lock-fields-edit"];
	let featEmailAvail = feat["agent.unique.email.address"];
	let prefMode = admin.agent.prefMode;
	if (typeof prefMode === "undefined") {
		prefMode = false;
	}
	let adminLevelSrc, interfaceLangSrc, langSrc, timeZone = emptyArray, availAreas, prefSal, prefSig
		, prefSalAreas, prefSigAreas, allowedMaxChatSess = false;
	let aventaEnabled = (wfs.data != null && wfs.data.aventaEnabled);
	let sipEnabled = (wfs.data != null && wfs.data.sipEnabled);
	if (admin.admin.activeId == -1 && !admin.agent.prefMode) {
		if (newAgent && newAgent.data) {
			adminLevelSrc = newAgent.data.adminLevelSrc;
			interfaceLangSrc = newAgent.data.interfaceLangSrc;
			langSrc = newAgent.data.langSrc;
			timeZone = newAgent.data.timeZone;
			availAreas = prefSal = prefSalAreas = prefSig = prefSigAreas = emptyArray;
			allowedMaxChatSess = feat["chat.max-active-sessions.admin-edit"];
		}
	} else {
		adminLevelSrc = (data && data.adminLevelSrc) ? data.adminLevelSrc : [];
		interfaceLangSrc = (data && data.interfaceLangSrc) ? data.interfaceLangSrc : [];
		langSrc = (data && data.langSrc) ? data.langSrc : [];
		timeZone = (data && data.timeZone) ? data.timeZone : [];
		availAreas = (data && data.availableAreas) ? data.availableAreas : [];
		prefSal = (data && data.preferredSalutation) ? data.preferredSalutation : [];
		prefSalAreas = (data && data.salutationAreas) ? data.salutationAreas : [];
		prefSig = (data && data.preferredSignature) ? data.preferredSignature : [];
		prefSigAreas = (data && data.signatureAreas) ? data.signatureAreas : [];
		allowedMaxChatSess = (data && data.maxAllowedChatConfig) ? data.maxAllowedChatConfig : false;
	}
	if (langSrc && langSrc.length==0) {
		langSrc = getAdminLanguageList(store);
	}
	return {
		show: admin.agent.showEdit,
		prefMode: admin.agent.prefMode,
		multipleChatbotProviders: allowMultipleChatbotProvidersMemo(store),
		activeId: admin.admin.activeId,
		chatbotLibraries: knowledgebasesMemo(store),
		chatbotProviders: providersMemo(store),
		dataReady: !admin.agentData.wip,
		input: (prefMode ? admin.agent.input : admin.admin.input),
		passwordMinReq: feat["password-min-requirement"],
		showPersonalisationPreview: feat["agent.hide-personalisation-preview"],
		areaSalutations: (admin.agentAreaSalutation.data ? admin.agentAreaSalutation.data : []),
		areaSignatures: (admin.agentAreaSignature.data ? admin.agentAreaSignature.data : []),
		selectedAreaSalutationList: (prefMode ? admin.agent.selectedAreaSalutationList : admin.admin.selectedAreaSalutationList),
		selectedAreaSignatureList: (prefMode ? admin.agent.selectedAreaSignatureList : admin.admin.selectedAreaSignatureList),
		avatarPreview: (prefMode ? admin.agent.avatarPreview : admin.admin.avatarPreview),
		currentUser: (data && data.currentUser) ? data.currentUser : '',
		currentLogin: (initialChatData && initialChatData.currentLogin) ? initialChatData.currentLogin : '',
		adminLevelSrc: adminLevelSrc,
		availableAreas: availAreas,
		preferredSalutation: prefSal,
		aventaEnabled: aventaEnabled,
		sipEnabled: sipEnabled,
		salutationAreas: prefSalAreas,
		preferredSignature: prefSig,
		signatureAreas: prefSigAreas,
		maxAllowedChatConfig: allowedMaxChatSess,
		interfaceLangSrc: interfaceLangSrc,
		langSrc: langSrc,
		timeZone: timeZone,
		userCanAccessSysadminMenu: (wfs.data ? wfs.data["sysadmin.access-system-menu"] : false),
		maxChatSessionSetByAdmin: feat["chat.max-active-sessions"],
		agentLockFieldsEdit: agentLockFieldsEdit,
		isValidLoginName: admin.admin.validLoginName,
		loginNameChecking: admin.validateAgent.wip,
		autoInsertAnswer: feat["cention-library"] && feat["alternative-answers"],
		previewFeature: feat["preview.inbox"] || feat["preview.my-errands"] || feat["preview.search"],
		previewSummaryFeature: feat["preview-summary"],
		chat: feat["chat"],
		listOfSoundOutputDevices: admin.admin.listOfSoundOutputDevices,
		agentEmailAvailable: admin.admin.agentEmailAvailability,
		featEmailAvail: featEmailAvail,
		proficiencyList: skillProficiencySelector(store),
		skillList: skillsAdminSelector(store),
		adminStatus: admin.admin.adminStatus,
	};
};

const mapAgentEditDispatchToProp = (dispatch, props) => {
	return {
		onLoad: (pref) => {
			if (pref) {
				let param = "0/true";
				dispatch(fetchAgentData(param));
				if (features["sip-enabled"]) {
					dispatch(checkSoundOutputDevices());
				}
			}
			dispatch(resetEmailAvail());
		},
		onLoadEdit: () => {
			if(cflag.IsActive("2023-09-29.CEN-1599.skills.admin.page")) {
				dispatch(fetchSkillProficiency());
				dispatch(fetchSkills());
			}
		},
		onClose: () => {
			dispatch(toggleAgentEdit(false, false)); //FIXME: currently only agent Pref is using this.
			dispatch(resetEmailAvail());
		},
		onLoadKnowledgeBaseList: () => dispatch(onceKnowledgeBaseList()),
		onGetAreaSalutations: (area) => {
			dispatch(fetchAgentSalutations(area));
		},
		onGetAreaSignatures: (area) => {
			dispatch(fetchAgentSignatures(area));
		},
		onAddMP: (id, type, areaId) => {
			dispatch(addNewAgentMP(id, type, areaId));
		},
		onDeleteMP: (id, type) => {
			dispatch(deleteAgentMP(id, type));
		},
		onAddAvatar: (preview, isPref) => {
			dispatch(addAgentPrefAvatar(preview, isPref));
		},
		onRemoveAvatar: () => {
			dispatch(deleteAgentPrefAvatar());
		},
		onChangeAgentInput: (field, value, prefMode) => {
			dispatch(setAgentInput(field, value, prefMode));
		},
		onSaveAgent: (isPref) => {
			dispatch(adminActionStatus({ status: 1, msg: I("Pending") }));
			dispatch(AgentValidateExternalID(isPref))
		},
		getTwoFANewSecret: () => {
			dispatch(doGetTwoFAGetNewSecret())
		},
		onVerifyTwoFAToken :(twofa_secret,twofa_token) => {
			dispatch(doVerifyTwoFAToken({ twofa_secret : twofa_secret, twofa_token:twofa_token}))
		},
		onValidateAgent: (params) => {
			dispatch(validateAgent(params));
		},
		onCheckAgentEmailAvail: (id, email, prefMode) => {
			dispatch(checkAgentEmailAvail(id, email, prefMode));
		},
		onSaveAgentSkill: (params) => {
			dispatch(saveAgentSkill(params));
		},
		onRemoveAgentSkill: (id) => {
			dispatch(removeSkillAgent(id));
		},
		onDismissAlert: () => {
			const alertMsg = {
				show: false,
				msg: "",
				type: ""
			}
			dispatch(adminActionAlert(alertMsg));
		}
	}
};

const withAgentEdit = connect(
	mapAgentEditStateToProp
	, mapAgentEditDispatchToProp
);

export const AgentEditCtnr = withAgentEdit(AgentPref);

const mapAccessKeyProps = (state, props) => ({
	accessKeyData: adminAgentAccessKeysSelector(state, props),
	accessKeyURLCreator: adminAgentAccessKeyURL,
	activeUserID: getMyId(state)
})

const mapAccessKeyDispatch = dispatch => ({
	onDeleteAgentAccessKey: (...args) => dispatch(deleteAdminAgentAccessKey(...args)),
	onCreateAgentAccessKey: (...args) => dispatch(createAdminAgentAccessKey(...args)),
	onLoadAgentAccessKeys: (...args) => dispatch(listAdminAgentAccessKeys(...args))
})

export const AgentFormCtnr = compose(
	withAgentEdit,
	connect(mapAccessKeyProps, mapAccessKeyDispatch),
	withAgentTimezonOffset
)(AgentForm);

const mapAdminListStateToProp = store => {
	const server = store.server
		, app = store.app
		, admin = app.admin
		, ui = admin.ui
		, adminSt = admin.admin
		, wf = app.workflow
		;
	return {
		view: ui.view,
		list: getAdminListSelector(store),
		langSrc: adminSt.langSrc,
		order: listColumnSelector(store),
		show: adminSt.show,
		showInline: adminSt.showInline,
		showList: adminSt.showList,
		activeId: adminSt.activeId,
		areaList: onlyActiveAreasSelector(store),
		knowledgeBaseList: wf.knowledgeBaseList.data ? wf.knowledgeBaseList.data.list : emptyArray,
		orgAreas: normalizedOrgAreasMemoize(store),
		filter: adminSt.filter,
		agentUploadInProgress: adminSt.uploadingAgent,
		currentSortField: currentSortFieldMemo(store),
		currentSortOrder: currentSortOrder(store),
		routeTagsList: routingTagsSelector(store),
		agentList: getAgentListSelector(store),
		stunTurnList: getStunTurnList(store),
		defaultList: getAdminListDefSelector(store),
		allTagsList: tagsListSelector(store),
		nonExpandablesTags: getNonExpandables(store),
		adminActionAlert: adminSt.adminAlert,
		isLoading: getAdminListWipSelector(store),
	};
};

const mapAdminListDispatchToProp = (dispatch, props) => {
	return {
		onConfirmUpload: (view, file) => {
			if (process.env.NODE_ENV !== 'production') {
				console.log("confirm file upload:", { view, file });
			}
			if (view === M_REVIEW_KEYWORDS) {
				return dispatch(uploadReviewAddresses(file));
			}
		},
		onDownload: view => {
			if (process.env.NODE_ENV !== 'production') {
				console.log("admin download:", { view });
			}
			if (view === M_REVIEW_KEYWORDS) {
				return dispatch(downloadReviewAddress());
			}
		},
		onError: err => {
			let message;
			if (typeof err.message === "string") {
				message = err.message;
			} else {
				message = err;
			}
			dispatch(togglePopAlert(message));
		},
		onDismissAlert: () => {
			const alertMsg = {
				show: false,
				msg: "",
				type: ""
			}
			dispatch(adminActionAlert(alertMsg));
		},
		onLoad: (view, freshLoad) => {
			dispatch(changeAdminView(view));
			if (!freshLoad) {
				// TODO: this is wrong code because freshLoad always true and
				// this part never been executed.
				// TODO: need more investigations for above todo and this todo as
				// switching between view that do NOT has common admin list component,
				// such as 'chatbots' view to common admin list such as reviewAddresses
				// will cause more async trigger.
				dispatch(fetchAdminList(view));
			}
		},
		onLoadList: (view) => { // TODO: seem useless - remove this
			dispatch(fetchAdminList(view));
		},
		onLoadToEdit: (id, view, popout, list) => {
			dispatch(resetEmailAvail());
			if (view === M_CHATWIDGETDL || view === M_FAQWIDGETDL || view === M_VOICEWIDGETDL) {
				dispatch(toggleAdminEdit(id, true, popout, false));
			} else if (view === M_GENERATIVE_AI_DOCUMENT || isBlacklist(view) || isBlacklistResp(view) || isWhitelist(view) || isToolManager(view)) {
				dispatch(getAdminDataFromList(id, list, view));
				dispatch(toggleAdminEdit(id, true, popout, false));
			} else {
				dispatch(toggleAdminEdit(id, true, popout, true));
			}
			if (!popout) {
				let param = {};
				if (view === ADMIN_VIEW_MAP["wordlist"]) {
					dispatch(getWordlistData({ id: id }));
				} else if (view === M_MY_SIGNATURE) {
					if (id === 0) {
						dispatch(resetAdminInput());
					}
					dispatch(fetchOneSignature(idPersonalParam(id, "personal")));
				} else if (view === M_MY_SALUTATION) {
					if (id === 0) {
						dispatch(resetAdminInput());
					}
					dispatch(fetchOneSalutation(idPersonalParam(id, "personal")));
				} else if (view === M_STUN_TURN) {
					if (id === 0) {
						dispatch(resetAdminInput());
					}
					if (typeof list !== "undefined") {
						dispatch(getAdminDataFromList(id, list, view));
					}
				} else if (view === ADMIN_VIEW_MAP["addressbook-personal"]
					|| view === ADMIN_VIEW_MAP["addressbook"]
					|| view === ADMIN_VIEW_MAP["externalexpert-personal"]
					|| view === ADMIN_VIEW_MAP["externalexpert"]
					|| view === ADMIN_VIEW_MAP["companies"]
					|| view === M_CHATWIDGETDL
					|| view === M_FAQWIDGETDL
					|| view === M_VOICEWIDGETDL
					|| view === M_TAG
				) {
					if (typeof list !== "undefined") {
						dispatch(getAdminDataFromList(id, list, view));
					}
					if (view === M_TAG && id === 0) {
						dispatch(setAdminInput("level", 1));
					}
				} else if (view === ADMIN_VIEW_MAP["filearchive"]) {
					if (id === 0) {
						dispatch(resetAdminInput());
					}
					param = { id: id }
					dispatch(oneFileArchive(param));
				} else if (view === ADMIN_VIEW_MAP["keywords"] ||
					view === ADMIN_VIEW_MAP["autotags"] ||
					view === ADMIN_VIEW_MAP["sip"]) {
					if (id === 0) {
						dispatch(resetAdminInput());
					}
					param = { id: id }
					dispatch(sstAsyncs[view].one(param));
				} else if (view === M_REVIEW_KEYWORDS) {
					return dispatch(openEditForm(view, id));
				} else if (view === M_SALUTATION) {
					if (id === 0) {
						dispatch(resetAdminInput());
					}
					dispatch(fetchOneSalutation(idPersonalParam(id, "")));
				} else if (view === M_SIGNATURE) {
					if (id === -1) {
						dispatch(resetAdminInput());
					}
					dispatch(fetchOneSignature(idPersonalParam(id, "")));
				} else if (view === M_TEMPLATE || view === M_TEMPLATE_WHATSAPP) {
					if (id === 0) {
						dispatch(resetAdminInput());
					}
					dispatch(sstAsyncs[M_TEMPLATE].one(id));
				} else if (view === M_QUICK_REPLY) {
					if (id === 0) {
						dispatch(resetAdminInput());
					}
					dispatch(sstAsyncs[M_QUICK_REPLY].one(id));
				} else if (view === M_ERRANDINTERNALSTATE) {
					dispatch(sstAsyncs[M_ERRANDINTERNALSTATE].one(id));
				} else if (view === ADMIN_VIEW_MAP["agents"]) {
					if (id === -1) {
						dispatch(resetAdminInput());
						dispatch(agentCreateNew());
					} else {
						param = id + "/false";
						dispatch(fetchAgentData(param));
					}
				} else if (view == M_AGENTSIPSTATUS) {
					if (id === 0) {
						dispatch(resetAdminInput());
					}
					dispatch(sstAsyncs[M_AGENTSIPSTATUS].one(id));
				} else if (view == M_CLASSIFIER) {
					if (id === 0) {
						dispatch(resetAdminInput());
					}
					dispatch(sstAsyncs[M_CLASSIFIER].one(id));
				} else if (view === M_API_CALLBACK || view === M_API_ACCESSTOKENS) {
					if (id === 0) {
						dispatch(resetAdminInput());
					}
					if (typeof list !== "undefined") {
						dispatch(getAdminDataFromList(id, list, view));
					}
				}
			}
		},
		onActivateAgentSipStatus: (id, status) => {
			dispatch(doActivateAgentSipStatus(id, status));
		},
		onActivateStunTurnStatus: (id, status) => {
			dispatch(doActivateStunTurnStatus(id, status));
		},
		onDeleteFromList: (field, view) => {
			dispatch(removeAdminData(field, view));
		},
		onFilterAdminList: (view, val, field) => {
			if (view === M_REVIEW_KEYWORDS) {
				dispatch(setAdminListFilter(field, val));
			} else {
				// TODO: change all to use set-admin-list-filter as it allows
				// creation of general filter that independent of view.
				dispatch(filterAdminList(view, val, field));
			}
			dispatch(fetchAdminList(view, val)); //TODO: move this out from here
		},
		onActivateAgent: (status, id) => {
			if (status) {
				dispatch(doActivateAgent(id));
			} else {
				dispatch(doDeactivateAgent(id));
			}
		},
		onUnlockAgent: (id) => {
			dispatch(doUnlockAgent(id));
		},
		onUploadAgents: (files, to) => {
			dispatch(agentUploadListAttachment(files));
		},
		onUploadAutoTag: (files, to) => {
			dispatch(uploadAutoTagFile(files));
		},
		onUploadTags: (files, to) => {
			dispatch(uploadTagsFile(files));
		},
		onUploadAddressBook: (files) => {
			dispatch(uploadAddressBookList(files));
		},
		onStopCheckImportStatus: () => {
			dispatch(stopRunAgentImportStatus());
		},
		onDownloadFileArchive: id => {
			dispatch(doDownloadFileArchive(id));
		},
		onSortFields: p => {
			dispatch(sortAdminTableFields(p));
		},
		onSortOrder: p => {
			dispatch(sortAdminTableOrder(p));
		},
		onCloseForm: () => {
			dispatch(toggleAdminEdit(0, false, false, true));
		}
	}
};

export const AdminListCtnr = connect(mapAdminListStateToProp, mapAdminListDispatchToProp)(AdminList);

const mapAdminEditStateToProp = (store, props) => {
	const {
		activeId
		, archiveImages
		, input
		, insertionText
		, show
		, showInline
		, uploadingAgent
		, validLoginName
		, interactive
		, quickReply
		, adminStatus
	} = adminAdmin(store);
	return {
		view: getCurrentAdminView(store),
		activeId,
		archiveImgs: archiveImages,
		show,
		showInline,
		input,
		insertionText,
		langSrc: getAdminLanguageList(store),
		interactive,
		quickReply,
		adminStatus,
		agentList: getAgentListSelector(store),
		channels: getAdminChannelSelector(store),
		allChannels: servicesByType(store),
		ckeditorSettings: ckeditorSettings(store),
		areaList: onlyActiveAreasSelector(store),
		knowledgeBaseList: knowledgebasesMemo(store),
		agentUploadInProgress: uploadingAgent,
		isValidLoginName: validLoginName,
		collabSlackEnabled: getCollabSlackEnabled(store),
		collabJiraEnabled: getCollabJiraEnabled(store),
		collabMSTeamEnabled: getCollabMSTeamEnabled(store),
		collabGoogleChatEnabled: getCollabGoogleChatEnabled(store),
		collabChannels: getCollabChannelsListSelector(store, props),
		agentDataReady: !store.app.admin.agentData.wip,
		fileArchiveData: fileArchiveAdminSelector(store),
		selectedFileArchive: selectedFileArchive(store),
		agent: agentNameSelector(store),
		stunTurnList: getStunTurnList(store),
		stunTurnServices: getStunTurnServices(store),
		selectedStunTurnServices: "",
		connectedAreaList: onlyActiveConnectedAreasSelector(store),
		routeTagsList: routingTagsSelector(store),
		tagsList: tagsListSelector(store),
		subTagsList: subTagsListSelector(store),
		jwtChannels: jwtChannelSelector(store),
		jwtUsers: jwtUserSelector(store),
		jwtAPIVersion: jwtAPIVersion(store),
		jwtAccList: jwtChannelAccSelector(store)
	};
};

const mapAdminEditDispatchToProp = (dispatch, props) => {
	return {
		onLoad: () => {
			//console.log("on load AdminEdit");
		},
		onClose: (id) => {
			dispatch(toggleAdminEdit(id, true, false));
		},
		onChangeAdminInput: (field, value, key) => {
			dispatch(setAdminInput(field, value, key));
		},
		onSaveAdmin: (view, createNew, id, input) => {
			dispatch(setAdminData(view, createNew, id, input));
		},
		onCancelEdit: () => {
			dispatch(toggleAdminEdit(0, false, false));
		},
		onOpenInsertProtectedContentReply: () => dispatch(showInsertProtect(true)),
		onRemoveAvatar: (id) => {
			dispatch(removeEEAvatar(id));
		},
		onResetInsertionText: () => dispatch(resetInsertionEditorContent()),
		onValidateAgentSipStatus: (id, name) => dispatch(validateAgentSipStatus({ id, name })),
		onChangeInteractive: (field, value) => {
			dispatch(setAdminInput(field, value, WHATSAPP_INTERACTIVE));
		},
		onChangeQuickReply: (field, value, extrakey) => {
			dispatch(setAdminInput(field, value, M_QUICK_REPLY, extrakey));
		},
		onDeleteFromList: (field, view) => {
			dispatch(removeAdminData(field, view));
		},
		onAppendSubTags: (params, view) => {
			dispatch(adminTagAppend(params, view, false));
		},
		onEmptySubTagsWarning: () => {
			dispatch(togglePopAlert(I("Please add sub tags first")));
		},
		onCreateNew: (params, view, list) => {
			if (typeof list !== "undefined") {
				if (params.id === 0) {
					//save the newly created tag
					dispatch(setAdminData());
				} else {
					//create new sub tag for existing tag
					dispatch(setAdminInput("parentId", params.id));
					dispatch(setAdminInput("level", params.newLevel));
					dispatch(setAdminInput("tagName", ""));
					dispatch(setAdminInput("tagId", 0));
					dispatch(setAdminInput("id", 0));
					dispatch(setAdminInput("connectedTags", []));
					dispatch(setAdminInput("connectedTagsList", []));
					dispatch(setAdminInput("connectedTagsLen", 0));

					//toggle admin edit toggleAdminEdit
					dispatch(toggleAdminEdit(0, true, false, list));
				}
			}
		},
		onLoadToEditRow: (id, view, list) => {
			if (view === M_TAG) {
				if (typeof list !== "undefined") {
					dispatch(getAdminDataFromList(id, list, view));
				}
				dispatch(toggleAdminEdit(id, true, false));
			}
		},
	}
};

const withAdminEdit = connect(
	mapAdminEditStateToProp
	, mapAdminEditDispatchToProp
);

export const AdminEditCtnr = withAdminEdit(AdminEdit);

export const AdminEditLeftViewCtnr = withAdminEdit(AdminEditLeftView);

export const AdminEditRightViewCtnr = withAdminEdit(AdminEditRightView);

export const AdminEditInlineCtnr = withAdminEdit(AdminEditInline);

const mapStatePopupInsertProtect = (state, props) => {
	const {
		archiveImgs
		, languageSrc
		, ...ckeditorSettings
	} = insertProtectCkeditorSettings(state)
		;
	return {
		archiveImgs
		, ckeditorSettings
		, ckeditor: insertProtectCkeditor(state)
		, show: state.app.modals.showInsertProtect
		, langSrc: languageSrc
	};
};

const ckeInsertorMap = {
	[IP_ADMIN]: insertEditorContent
	, [IP_MANUAL]: insertProtectedReplyContent
	, [IP_WORKFLOW]: insertProtectedReplyContent
};

const mapDispatchPopupInsertProtect = (dispatch, props) => ({
	onClose: () => {
		if (process.env.NODE_ENV !== 'production') {
			console.log("dbg: close popup insert protect");
		}
		dispatch(closeInsertProtect());
	}
	, onInsert: (ckeditor, content) => {
		if (process.env.NODE_ENV !== 'production') {
			console.log("dbg: inserting protect content:", { content });
		}
		const insertor = ckeInsertorMap[ckeditor];
		if (typeof insertor === "function") {
			dispatch(insertor(content));
		}
	}
});

export const PopupInsertProtectCtnr = connect(
	mapStatePopupInsertProtect
	, mapDispatchPopupInsertProtect
)(PopupInsertProtect);

const mapStateToProp = store => {
	const server = store.server, app = store.app, admin = app.admin, ui = admin.ui;
	const wf = store.app.workflow;
	const s = {
		showSideBar: wf.ui.showSideBar,
		collapseSideBar: wf.ui.collapseSideBar,
		subMenus: server.menu.adminMenu.sub ? server.menu.adminMenu.sub : emptyArray,
		view: ui.view,
		agentList: emptyArray,
		adminStatus: admin.admin.adminStatus
	};
	return s;
};

const mapDispatchToProp = (dispatch, props) => {
	return {
		onLoad: (view) => {
			dispatch(onceLanguageList());
		},
		onToggleSideBar: (toggle) => {
			//dispatch(toggleAdminSidebar(toggle)); // TODO: Remove this on redux, unused
			dispatch(selectToggleSideBar(toggle));
		},
		onCollapseSideBar: (toggle) => {
			dispatch(selectCollapseSideBar(toggle));
		},
		onChangeView: (view, url) => {
			dispatch(push(url)).then(() => dispatch(changeAdminView(view)));
		},
		onMainView: () => {
			dispatch(resetWorkflowView());
		},
	}
};

const AdminCtnr = connect(mapStateToProp, mapDispatchToProp)(Admin);

export default AdminCtnr;
